@import url(../../pages/App/App.css);

.header {
  z-index: 4;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 1.6rem);
  max-width: 128rem;
  height: 6.2rem;
  padding: var(--space-s) var(--space-m);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 0.1rem solid rgba(147, 170, 194, 0.4);
  border-radius: var(--space-s);
  backdrop-filter: blur(10px);
}

.header__nav {
  /* width: 100%; */
  height: 100%;
  display: flex;
  align-items: center;
  gap: var(--space-l);
}
