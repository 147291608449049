@import url("../../pages/App/App.css");

.contact-modal-container {
  z-index: 3;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - calc(var(--space-s) * 2));
  max-width: 128rem;
  height: calc(100% - calc(var(--space-s)));
  padding: 7rem 0;
  display: flex;
  justify-content: flex-end;
  backdrop-filter: blur(10px);
}

.contact-modal-container--close {
  visibility: hidden;
  backdrop-filter: blur(0);
  transition: all 1s;
  transition-delay: visibility 0.6s;
}

.contact-modal-container--open {
  visibility: visible;
  backdrop-filter: blur(10px);
  transition: all 0.6s;
}

.contact-modal {
  width: 100%;
  max-width: 56rem;
  max-height: 42rem;
  padding: var(--space-s);
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: var(--space-m);
  background-color: var(--dark-blue-tr);
  border: 0.1rem solid rgba(147, 170, 194, 0.4);
  border-radius: var(--space-m);
}

.contact-modal--close {
  transform: scale(0) translateY(-8rem);
  transform-origin: top right;
  transition: transform 0.3s;
}

.contact-modal--open {
  transform: scale(1) translateY(0);
  transform-origin: top right;
  transition: transform 0.6s;
}

.contact-modal__title {
  font-family: "Audiowide";
  font-weight: 400;
  font-style: normal;
  color: var(--white);
}

.button-email-container {
  position: relative;
  width: 100%;
  height: 4.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.1rem solid rgba(147, 170, 194, 0.4);
  border-radius: var(--space-s);
  overflow: hidden;
  transition: border 0.5s;
}

.button-email-container:hover {
  border: 0.1rem solid var(--cyan);
  transition: border 0.3s;
}

.button-email-container:hover .button-email__button {
  color: var(--cyan);
  transition: color 0.3s;
}

.button-email__button {
  width: 100%;
  height: 100%;
  font-family: "Exo 2", sans-serif;
  font-size: 1.8rem;
  color: var(--white);
  letter-spacing: 0.2rem;
  border-radius: var(--space-s);
  transition: color 0.3s;
}

@media (max-width: 768px) {
  .button-email__button {
    font-size: 1.6rem;
  }
}
