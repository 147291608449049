/*********************************************
VARIABLES:
Definición de variables de las hojas de 
estilo
**********************************************/
:root {
  /*********************************************
  COLORES:
  Definición de colores base de la página
  **********************************************/
  --black: #000000;
  --white: #ffffff;
  --dark-blue: rgba(10, 11, 35, 1);
  --soft-blue: rgba(147, 170, 194, 1);
  --cyan: rgba(0, 255, 233, 1);

  /*********************************************
  DEGRADADOS:
  Definición de degradados para tarjetas y
  elementos como los proyectos de esta sección
  **********************************************/
  --white-border: rgba(147, 170, 194, 0.5);
  --hover-border: rgba(147, 170, 194, 0.5);

  --dark-blue-tr: rgba(10, 11, 35, 0.9);

  /*********************************************
  MEDIDAS:
  Definición de medidas de espaciados que se
  agregarán por defecto al portafolio
  **********************************************/
  --space-s: 0.8rem;
  --space-m: 1.2rem;
  --space-l: 2.4rem;
  --space-xl: 3.2rem;
  --space-xxl: 4.6rem;
}

/*********************************************
RESET:
Reseteo del CSS para ajustar el proyecto
a medidas relativas e implementación
de clases definidas para elementos específicos
**********************************************/
html {
  font-size: 62.5%;
  font-family: "Exo 2", sans-serif;
}

* {
  margin: 0;
  box-sizing: border-box;
}

body {
  width: 100%;
  height: 100vh;
  padding: var(--space-s);
  background: var(--dark-blue);
}

ul {
  padding: 0;
  list-style: none;
}

p,
h1,
h2,
h3,
li {
  color: var(--soft-blue);
}

button {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: "Audiowide";
  /* font-family: "Exo 2", sans-serif; */
  font-weight: 400;
  font-style: normal;
  background: none;
  border: none;
  color: var(--dark-blue);
}

a {
  text-decoration: none;
}

/*********************************************
TEXTOS - TAMAÑO:
Asignación predeterminada en el proyecto
del tamaño de los textos.
**********************************************/
.text-s {
  font-size: 1.6rem;
}

.text-m {
  font-size: 1.8rem;
}

.text-l {
  font-size: 2.4rem;
}

.text-title {
  font-size: 3.6rem;
  font-family: "Audiowide";
  font-weight: 400;
  font-style: normal;
}

.text-regular {
  font-weight: lighter;
}

.text-bold {
  font-weight: bolder;
}

/*********************************************
TEXTOS - ALINEACIONES:
Asignación predeterminada en el proyecto
de las alineaciones
**********************************************/
.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

/*********************************************
PAGES - DEFAULT:
Asignación de estilo por defecto para todas
las páginas.
**********************************************/

.page-container {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  /* width: calc(100% - 1.6rem); */
  width: 100%;
  max-width: 128rem;
  min-height: calc(100dvh - var(--space-s));
  padding-top: 12.4rem;
  animation: animation-open-page 0.8s both;
}

@keyframes animation-open-page {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .page-container {
    padding-bottom: 7.2rem;
  }
}
