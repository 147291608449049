@import url(../App/App.css);

.skills-container {
  display: flex;
  flex-direction: column;
  gap: var(--space-xl);
}

/* *************************************************
  Estilos del contenedor general que describe las
  habilidades de la página
************************************************* */
.skills-wrap {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
