@import url(../../pages/App/App.css);

.card-project {
  max-width: 41rem;
  min-height: 42rem;
  padding: 0.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    180deg,
    rgba(147, 170, 194, 0.2) 0%,
    rgba(147, 170, 194, 0) 63.54%
  );
  border-radius: var(--space-m);
}

.card-project--mask {
  width: 100%;
  height: 100%;
  padding: var(--space-m);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: linear-gradient(
    180deg,
    rgba(10, 11, 35, 1) 20%,
    rgba(147, 170, 194, 0.1) 90%
  );
  border-radius: var(--space-m);
}

.card-project__top {
  margin-bottom: var(--space-l);
  display: flex;
  flex-direction: column;
  gap: var(--space-l);
}

.card-project__image {
  width: 100%;
  height: 18rem;
  border-radius: var(--space-s);
  overflow: hidden;
}

.card-project__image img {
  width: 100%;
  filter: saturate(150%) brightness(80%) contrast(120%);
}

.card-project__text :nth-child(1) {
  margin-bottom: var(--space-m);
}

.card-project__footer {
  width: 100%;
  height: 4.2rem;
  padding: 0.6rem var(--space-l);
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0.1rem solid rgba(147, 170, 194, 0.4);
  border-radius: var(--space-s);
}

.card-project__footer--year {
  width: 100%;
  color: var(--cyan);
}

.card-project__footer--links {
  display: flex;
  column-gap: var(--space-l);
}
