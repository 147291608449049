@import url(../../pages/App/App.css);

.navbar {
  height: 100%;
  display: flex;
  align-items: center;
  column-gap: var(--space-xl);
  flex-wrap: nowrap;
}

.navbar-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.navbar-link__text {
  /* font-weight: bold; */
  letter-spacing: 0.1rem;
  font-style: normal;
  transition: color 0.8s;
}

.navbar-link__decoration {
  width: 0.4rem;
  height: 0.2rem;
  border-radius: 0.8rem;
  background-color: var(--white-border);
  transform: scale(0);
  transition: all 0.5s;
}

/* ********************************************
Estilado de clases que crean animación de 
interaccón para todos los links
******************************************** */

.navbar-link:hover .navbar-link__decoration {
  width: 100%;
  border-radius: 0.8rem;
  background-color: var(--white-border);
  transform: scaleX(1);
  transition: all 0.4s;
}

.decoration-active {
  width: 100%;
  background-color: var(--cyan);
  transform: scale(1);
}

.text-active {
  font-weight: bold;
  color: var(--cyan);
  transition: color 0.6s;
}

/* ********************************************
Inserción de los Media Queries
******************************************** */

@media (max-width: 800px) {
  .navbar {
    display: none;
  }
}
