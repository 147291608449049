@import url(../../pages/App/App.css);

.button-contact {
  position: relative;
  height: 100%;
  min-width: 12rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-radius: 0.4rem;
}

.button-contact button {
  width: auto;
  min-width: 4.2rem;
  padding: 0 var(--space-m);
  background: rgba(147, 170, 194, 0.4);
  border-radius: 0.4rem;
  color: var(--cyan);
  transition: all 0.8s;
}

.button-contact--close {
  transform: translateY(6.8rem);
}

/* ********************************************
Estilos del hover
******************************************** */

.button-contact:hover button {
  background: var(--cyan);
  color: var(--dark-blue);
  transition: all 0.3s;
}
