@import url(../App/App.css);

.home-page {
  margin-top: 12.4rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, auto);
}

.home__name {
  width: 100%;
  grid-column: 1/3;
}

.home__middle-line {
  grid-column: 1 / 4;
  height: 0.1rem;
  margin: var(--space-s) 0;
  background: linear-gradient(90deg, rgba(0, 255, 233, 0) 0%, #00ffe9 100%);
  border-radius: var(--space-s);
}

.home__text {
  grid-column: 2 / 4;
  font-size: 2.4rem;
}

@media (max-width: 768px) {
  .home__text {
    font-size: 1.6rem;
  }
}
