.footer-container {
  margin-top: var(--space-l);
  /* padding: var(--space-m) 0; */
  background: linear-gradient(
    90deg,
    rgba(147, 170, 194, 0) 0%,
    rgba(147, 170, 194, 0.1) 50%,
    rgba(147, 170, 194, 0) 100%
  );
  /* background: linear-gradient(
    0deg,
    rgba(147, 170, 194, 0.1) 0%,
    rgba(147, 170, 194, 0) 100%
  ); */
  border-radius: var(--space-m);
}
