@import url(../App/App.css);

.projects-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.projects-container h2 {
  margin-bottom: var(--space-l);
}

.projects-container hr {
  width: 100%;
  margin-bottom: var(--space-l);
  border-top: 0.1rem solid var(--white-border);
  border-bottom: none;
  border-left: none;
  border-right: none;
}

.projects__filter {
  width: 100%;
  margin-bottom: var(--space-l);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: var(--space-s);
}

.projects-grid {
  width: 100%;
  margin-bottom: var(--space-l);
  padding: 0 0.1rem;
  display: grid;
  justify-items: center;
  grid-template-columns: repeat(auto-fit, minmax(30.4rem, 1fr));
  gap: var(--space-xl);
}
