@import url(../../pages/App/App.css);

.mobile-nav {
  z-index: 4;
  position: fixed;
  bottom: var(--space-s);
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 1.6rem);
  max-width: 42rem;
  height: 6.2rem;
  padding: var(--space-s);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.1rem solid rgba(147, 170, 194, 0.4);
  border-radius: var(--space-s);
  backdrop-filter: blur(10px);
}

.nav-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.nav-element__link {
  display: flex;
  flex-direction: column;
}

.nav-element__link img {
  height: 2.6rem;
}

.nav-element__link p {
  font-size: 1.4rem;
}

/* ********************************************
Estilado de clases que crean animación de 
interaccón para todos los links
******************************************** */

.active-text {
  color: var(--cyan);
}

/* ********************************************
Inserción de los Media Queries
******************************************** */

@media (min-width: 801px) {
  .mobile-nav {
    display: none;
  }
}
