.grid-decoration {
  width: 100%;
  min-height: 28rem;
  max-height: 40.8rem;
  margin-top: 5rem;
  display: grid;
  grid-template-columns: repeat(14, 1fr);
  grid-template-rows: repeat(5, 1fr);
}

.grid-line__h {
  height: 0.1rem;
}

.grid-line__v {
  width: 0.1rem;
}

.back-gray__h {
  background: linear-gradient(
    90deg,
    rgba(147, 170, 194, 1) 0%,
    rgba(147, 170, 194, 0) 100%
  );
}

.back-cyan__h {
  background: linear-gradient(
    90deg,
    rgba(0, 255, 233, 1) 0%,
    rgba(0, 255, 233, 0) 100%
  );
}

.back-gray__v {
  background: linear-gradient(
    180deg,
    rgba(147, 170, 194, 1) 0%,
    rgba(147, 170, 194, 0) 100%
  );
}

.back-cyan__v {
  background: linear-gradient(
    180deg,
    rgba(0, 255, 233, 1) 0%,
    rgba(0, 255, 233, 0) 100%
  );
}

.position__h-1 {
  grid-column: 4 / 15;
  grid-row: 2;
}

.position__h-2 {
  grid-column: 3 / 15;
  grid-row: 3;
}

.position__h-3 {
  grid-column: 1 / 15;
  grid-row: 4;
  transform: rotate(180deg);
}

.position__h-4 {
  grid-column: 1 / 15;
  grid-row: 5;
}

.position__v-1 {
  grid-column: 2;
  grid-row: 2 / 6;
}

.position__v-2 {
  grid-column: 3;
  grid-row: 3 / 6;
  transform: scaleY(0.9);
  transform-origin: bottom;
}

.position__v-3 {
  grid-column: 4;
  grid-row: 4 / 6;
  transform: scaleY(1.2);
  transform-origin: bottom;
}

.position__v-4 {
  grid-column: 5;
  grid-row: 1 / 6;
}

.position__v-5 {
  grid-column: 9;
  grid-row: 3 / 6;
  transform: scaleY(1.2);
  transform-origin: bottom;
}
.position__v-6 {
  grid-column: 10;
  grid-row: 1 / 6;
}

.position__v-7 {
  grid-column: 11;
  grid-row: 2 / 6;
  transform: scaleY(1.1);
  transform-origin: bottom;
}

.position__v-8 {
  grid-column: 12;
  grid-row: 3 / 5;
  transform: scaleY(0.9);
  transform-origin: bottom;
}

.position__v-9 {
  grid-column: 13;
  grid-row: 2 / 6;
  transform: scaleY(0.9);
  transform-origin: bottom;
}

.position__v-10 {
  grid-column: 14;
  grid-row: 1 / 6;
}

@media (max-width: 768px) {
  .home__text {
    font-size: 1.6rem;
  }
}
