@import url(../../pages/App/App.css);

.skills-work {
  width: 100%;
  padding: var(--space-l);
  flex: 1 1 30rem;
  display: flex;
  flex-direction: column;
  gap: var(--space-xl);
}

.work-section__subtitle {
  text-transform: uppercase;
}

.work-section :nth-child(1) {
  color: var(--white);
}

.work-section :nth-child(2) {
  margin-bottom: var(--space-m);
}
