@import url(../App/App.css);

.about-me-section {
  position: relative;
  width: 100%;
  padding-bottom: var(--space-l);
  display: flex;
  flex-direction: column;
  gap: var(--space-xl);
  overflow: ;
}

.about-me__article {
  z-index: 3;
  padding: 0.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    180deg,
    rgba(147, 170, 194, 0.4) 0%,
    rgba(147, 170, 194, 0.1) 63.54%
  );
  border-radius: var(--space-l);
}

.article--mask {
  width: 100%;
  height: 100%;
  padding: var(--space-l);
  display: flex;
  flex-direction: column;
  gap: var(--space-m);
  background: var(--dark-blue);
  border-radius: var(--space-l);
}

.article-personal {
  max-width: 68rem;
  /* flex: 1 1; */
  align-self: flex-end;
}

.article-hobbies {
  max-width: 82rem;
}
