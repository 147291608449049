@import url(../../pages/App/App.css);

.skills-tools {
  padding: var(--space-m);
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: var(--space-s);
}

.tools__element {
  padding: var(--space-s);
  display: flex;
  flex: 1 1 16rem;
  gap: var(--space-s);
  align-items: center;
  border: 0.1rem solid var(--white-border);
  border-radius: var(--space-s);
  user-select: none;
  transition: box-shadow 1s;
}

.tools__element:hover {
  box-shadow: 0 0 0.8rem 0.2rem var(--hover-border);
  transition: box-shadow 0.3s;
}

.tools__element-icon {
  height: 2.4rem;
}

.tools__element-text {
  width: 100%;
}

.tools__element--animation {
  box-shadow: 0 0 0.8rem 0.2rem var(--hover-border);
  transition: box-shadow 0.6s;
  animation: animation-tools 0.6s both;
}

@keyframes animation-tools {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0.6;
  }
}
